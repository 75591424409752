var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto" },
    [
      _c("div", { staticClass: "flex-initial items-stretch" }, [
        _c("div", { staticClass: "grid grid-cols-8 gap-x-4 my-3" }, [
          _c(
            "button",
            {
              staticClass: "w-full px-4 py-2 mr-1 font-bold text-xs relative",
              class: [
                _vm.activeSelect === 0
                  ? "text-black bg-purple-400"
                  : "text-brown-grey bg-light-grey",
              ],
              on: {
                click: function ($event) {
                  return _vm.changeTarget(0)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("pages.administrators.agreements.provider")) +
                  " "
              ),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "w-full px-4 py-2 mr-1 font-bold text-xs relative",
              class: [
                _vm.activeSelect === 1
                  ? "text-black bg-purple-400"
                  : "text-brown-grey bg-light-grey",
              ],
              on: {
                click: function ($event) {
                  return _vm.changeTarget(1)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("pages.administrators.agreements.consumer")) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "border border-light-grey" }),
      ]),
      _c("FlTacList", {
        key: _vm.activeSelect,
        attrs: { tabSelect: _vm.activeSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }