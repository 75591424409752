var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        { staticClass: "input_text grid grid-cols-4 gap-x-4" },
        [
          _vm._m(0),
          _vm.displayFlag
            ? [
                _vm.isDelete
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "col-start-3 h-6 text-xs bg-base-yellow font-bold float-right",
                        on: {
                          click: function ($event) {
                            return _vm.clickButton("delete")
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("pages.administrators.agreements.delete")
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass:
                      "col-start-4 h-6 text-xs bg-base-yellow font-bold float-right",
                    on: {
                      click: function ($event) {
                        return _vm.clickButton("view")
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("pages.administrators.agreements.view")) +
                        " "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "col-span-2" }, [
      _c("img", {
        staticClass: "w-4 h-4 inline-block align-middle",
        attrs: { src: require("@/assets/image/tac_document.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }