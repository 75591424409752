var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-left" }, [
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-x-4 py-2" },
      [
        _c("div", { staticClass: "col-span-5 my-auto" }, [
          _c("div", [
            _c("span", { staticClass: "font-bold mr-2" }, [
              _vm._v(_vm._s(_vm.fileOutline) + ":"),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.fileDescription))]),
          ]),
        ]),
        _vm._l(_vm.uploadings, function (uploading, uploadingIndex) {
          return [
            _c(
              "div",
              {
                key: "progress-" + uploadingIndex,
                staticClass:
                  "col-start-6 col-span-7 border border-brown-grey relative",
              },
              [
                _c("div", {
                  staticClass: "absolute left-0 top-0 bottom-0 m-auto",
                  class: uploading.aborted
                    ? "bg-brown-grey"
                    : "bg-light-yellow",
                  style: { width: uploading.uploadProgress + "%" },
                }),
                _c(
                  "span",
                  {
                    staticClass:
                      "font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-center",
                    class: uploading.aborted ? "line-through" : "",
                  },
                  [_vm._v(" " + _vm._s(uploading.progressText) + " ")]
                ),
              ]
            ),
          ]
        }),
        _vm.stillCanUpload && !_vm.userIsConsumer
          ? [
              _c("div", { staticClass: "col-start-1 col-span-12 mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-xs" },
                  [
                    _c(
                      "FlCheckbox",
                      {
                        model: {
                          value: _vm.enabled,
                          callback: function ($$v) {
                            _vm.enabled = $$v
                          },
                          expression: "enabled",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pages.commons.quotes.check_file_format")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "col-start-1 col-span-12" },
                [
                  _c("FlDnD", {
                    attrs: {
                      extensions: _vm.calculatedExtensions,
                      disabled: !_vm.enabled,
                    },
                    on: { input: _vm.fileDropped },
                  }),
                ],
                1
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }