var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-8 gap-x-4 my-3" }, [
        _c("div", { staticClass: "col-span-8" }, [
          _c("div", { staticClass: "grid grid-cols-6 gap-x-4 my-3" }, [
            _vm.tacLatest ? _c("p", { staticClass: "col-span-4" }) : _vm._e(),
            _c(
              "button",
              {
                staticClass: "w-full px-4 py-2 mr-1 font-bold text-xs relative",
                class: [
                  _vm.response && _vm.response.approved_at === null
                    ? "text-brown-grey bg-light-grey"
                    : "bg-base-yellow font-bold",
                ],
                on: {
                  click: function ($event) {
                    return _vm.addTac(0)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("pages.administrators.agreements.add_major")
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "w-full px-4 py-2 mr-1 font-bold text-xs relative",
                class: [
                  _vm.response && _vm.response.approved_at === null
                    ? "text-brown-grey bg-light-grey"
                    : "bg-base-yellow font-bold",
                ],
                on: {
                  click: function ($event) {
                    return _vm.addTac(1)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("pages.administrators.agreements.add_minor")
                    ) +
                    " "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm.addAgreement && _vm.response
        ? _c("div", [
            _c("div", { staticClass: "grid grid-cols-4 gap-x-4 my-3" }, [
              _c(
                "div",
                { staticClass: "col-span-4 border border-grey rounded-xl" },
                [
                  _c("p", { staticClass: "my-3 mx-2" }, [
                    _vm._v("version " + _vm._s(_vm.response.version)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "grid grid-cols-2 gap-x-4 my-3 mx-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-span-1" },
                        [
                          _vm.uploadStatus.ja.status !== 7
                            ? _c("FlDnDUploader", {
                                attrs: {
                                  "v-model": _vm.uploadStatus.ja,
                                  outline: _vm.$t("commons.locales.ja"),
                                  locale: "ja",
                                  extensions: ["pdf"],
                                  fileTypeNumber: 99,
                                  fileCount: -1,
                                  imageStructs: _vm.imageStructsForType(),
                                },
                                on: {
                                  add: function ($event) {
                                    return _vm.createImageStructOfType(
                                      0,
                                      99,
                                      $event
                                    )
                                  },
                                  input: function ($event) {
                                    return _vm.isUploadStatis($event)
                                  },
                                },
                              })
                            : _c("FlTacFile", {
                                class: [
                                  _vm.uploadStatus.en.status !==
                                  _vm.uploadStatus.ja.status
                                    ? "relative top-1/2"
                                    : "",
                                ],
                                attrs: {
                                  isDelete: true,
                                  uuid: _vm.response.uuid,
                                  locale: "ja",
                                },
                                on: { clickButton: _vm.tacAction },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-span-1" },
                        [
                          _vm.uploadStatus.en.status !== 7
                            ? _c("FlDnDUploader", {
                                attrs: {
                                  "v-model": _vm.uploadStatus.en,
                                  outline: _vm.$t("commons.locales.en"),
                                  locale: "en",
                                  extensions: ["pdf"],
                                  fileTypeNumber: 99,
                                  fileCount: -1,
                                  imageStructs: _vm.imageStructsForType(),
                                },
                                on: {
                                  add: function ($event) {
                                    return _vm.createImageStructOfType(
                                      0,
                                      99,
                                      $event
                                    )
                                  },
                                  input: function ($event) {
                                    return _vm.isUploadStatis($event)
                                  },
                                },
                              })
                            : _c("FlTacFile", {
                                class: [
                                  _vm.uploadStatus.en.status !==
                                  _vm.uploadStatus.ja.status
                                    ? "relative top-1/2"
                                    : "",
                                ],
                                attrs: {
                                  isDelete: true,
                                  uuid: _vm.response.uuid,
                                  locale: "en",
                                },
                                on: { clickButton: _vm.tacAction },
                              }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "grid grid-cols-6 gap-x-4 my-3" }, [
                    _c("div", { staticClass: "col-start-6" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "w-4/5 px-4 py-2 mr-1 font-bold text-xs relative bg-base-yellow h-10",
                          class: [
                            _vm.uploadStatus.ja.status === 7 &&
                            _vm.uploadStatus.en.status === 7
                              ? ""
                              : "text-brown-grey bg-light-grey",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.approve()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "pages.administrators.agreements.approve"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.tacsList.length > 0
        ? _c(
            "div",
            [
              _vm._l(_vm.tacsList, function (tac, key) {
                return [
                  _c("div", { key: "tac-" + key, staticClass: "my-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "border border-grey rounded-xl",
                        class: [
                          tac.version !== _vm.tacLatest.version
                            ? "bg-light-grey"
                            : "",
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "grid grid-cols-2 gap-x-4 my-3 mx-2" },
                          [
                            _c("div", {}, [
                              _c("p", [
                                _vm._v("Version: " + _vm._s(tac.version)),
                              ]),
                            ]),
                            tac.version === _vm.tacLatest.version
                              ? _c("div", {}, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "w-4 h-4 inline-block float-right text-green-600",
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "h-5 w-5",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 20 20",
                                            fill: "currentColor",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              "fill-rule": "evenodd",
                                              d: "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z",
                                              "clip-rule": "evenodd",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "grid grid-cols-2 gap-x-4 my-3 mx-2" },
                          [
                            _c("FlTacFile", {
                              attrs: {
                                uuid: tac.uuid,
                                locale: "ja",
                                tacData: tac.translations,
                              },
                              on: { clickButton: _vm.tacAction },
                            }),
                            _c("FlTacFile", {
                              attrs: {
                                uuid: tac.uuid,
                                locale: "en",
                                tacData: tac.translations,
                              },
                              on: { clickButton: _vm.tacAction },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              }),
              _vm.isNextPage()
                ? [
                    _c(
                      "div",
                      { staticClass: "grid grid-cols-3 gap-x-4 my-3 mx-2" },
                      [
                        _c("div", { staticClass: "col-span-1" }),
                        _c(
                          "button",
                          {
                            staticClass:
                              "h-full w-full py-1 bg-very-light-grey text-xl font-semibold text-black",
                            class: [
                              false ? "bg-very-light-grey" : "bg-brown-grey",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.clickButton()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " + " +
                                _vm._s(
                                  _vm.$t("components.changeFieldButton.more")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _c("p", { staticClass: "h-8" }),
            ],
            2
          )
        : _vm._e(),
      _c("NoticeModal", {
        attrs: {
          modalName: "tacSelectVersion",
          title: "新バージョンを作成します（仮）",
          contents:
            "ビルドバージョンを1足したバージョンを作成します。よろしいですか？",
          buttons: ["キャンセル", "OK"],
        },
        on: {
          click: function ($event) {
            return _vm.updateVersion(_vm.versionIndex)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }